import React, {useEffect, useRef, useState} from 'react';
import {Button, Image} from "react-bootstrap";
import "./video.css";
import "../slideUp.css";
import {FaPlay} from "react-icons/fa";
import {highlightText} from "../../utils";
import logo from "../../logo.jpg";


const VideoItem = ({video, searchQuery, setVideoLoaded, videoLoaded}) => {
    const [loaded, setLoaded] = useState(false);
    const [showDiv, setShowDiv] = useState(false);

    const imgRef = useRef(null); // Ref for lazy loading

    const previewImage = `https://www.suencu.com/videos/${video.video_id}/preview.jpg`;
    const isReel = video.video_type === "Reels";

    // Lazy loading for images using Intersection Observer
    useEffect(() => {
        const imgElement = imgRef.current;

        if (videoLoaded) {
            setShowDiv(true);
            return;
        }

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    imgElement.src = imgElement.dataset.src;
                    setShowDiv(true);
                    setVideoLoaded(video.video_id);
                    observer.unobserve(imgElement); // Stop observing once loaded
                }
            });
        });

        if (imgElement) {
            observer.observe(imgElement);
        }

        return () => {
            if (imgElement) observer.unobserve(imgElement);
        };
    }, [previewImage, video, videoLoaded, setVideoLoaded, imgRef]);

    const handlePlay = () => {
        setLoaded(true);
        setShowDiv(true);
        setVideoLoaded(video.video_id);
    };

    return (
        <div className={`col-lg-${isReel ? '3' : '4'} col-sm-${isReel ? '6' : '8'} d-md-flex`}>
            <div className={"card p-2 m-3 border border-1 rounded video-card slide-up " + (showDiv ? "show" : "")}>
                {!loaded &&
                    <div className="video-placeholder bg-light-subtle d-flex justify-content-center">
                        <Image
                            ref={imgRef}
                            src={!videoLoaded ? logo : previewImage} // Placeholder
                            data-src={previewImage}
                            className={`card-img-top p-2 border border-1 rounded ${isReel ? "item-reels" : "item-video"}`}
                        />
                        <Button variant="primary" className="play-button" onClick={handlePlay}>
                            <div className="d-flex justify-content-center align-items-center"><FaPlay/></div>
                        </Button>
                    </div>
                }
                {loaded &&
                    <div className="d-flex align-items-center justify-content-center">
                        <video src={"https://www.suencu.com/videos/" + video.video_id + "/" + video.video_path}
                               className={`card-img-top p-1 border border-1 rounded ${isReel ? "item-reels" : "item-video"}`}
                               controls/>
                    </div>
                }
                <div className="card-body">
                    <h5 className="card-title">{highlightText(video.title, searchQuery)}</h5>
                    {!loaded && video.description && (
                        <div className="card-text video-card-text flex-md-grow-1">
                            <p className="video-p">{highlightText(video.description, searchQuery)}</p>
                        </div>
                    )}
                    {loaded && video.description && (
                        <div className="card-text video-card-text flex-md-grow-1">
                            <p className="video-p-loaded">{highlightText(video.description, searchQuery)}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default VideoItem;