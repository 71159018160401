import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {FaBars, FaBlog, FaPhone, FaQuestion, FaVideo} from "react-icons/fa";

const Navbar = () => {
    const [showCategories, setShowCategories] = useState(false);
    const [categories, setCategories] = useState([]);

    const location = useLocation(); // Hook to get the current URL location

    // Run this effect when the URL (location) changes
    useEffect(() => {
        // Whenever the URL changes and the device is mobile phone, set showCategories to false
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setShowCategories(false);
        }

    }, [location]); // The effect depends on `location`, so it runs when the URL changes

    useEffect(() => {
        // Fetch categories from the server
        const fetchCategories = async () => {
            try {
                const response = await fetch('https://www.suencu.com/fetch_category.php');
                const data = await response.json();
                setCategories(data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        if (categories.length === 0) fetchCategories();
    }, [categories]);

    return (
        <nav className="navbar navbar-expand-lg rounded" style={{backgroundColor: "#006400"}}>
            <div className="container-fluid">
                <div className="nav nav-pills w-100">
                    <Link to="/" className="navbar-brand text-white align-items-center">
                        <span className="d-lg-inline">Suencu</span>
                    </Link>
                    <button className="navbar-toggler mr-auto" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item dropdown">
                                <button className="flex-fill text-lg-center text-white nav-link align-items-center"
                                        onClick={() => setShowCategories(!showCategories)}>
                                    <span className="d-lg-inline">Kategoriler </span><FaBars/>
                                </button>
                            </li>
                            <li className="nav-item">
                                <Link to="/video"
                                      className="flex-fill text-lg-center text-white nav-link align-items-center">
                                    <span className="d-lg-inline">Videolar </span><FaVideo/>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/blog"
                                      className="flex-fill text-lg-center text-white nav-link align-items-center">
                                    <span className="d-lg-inline">Bloglar </span><FaBlog/>
                                </Link>
                            </li>

                        </ul>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to="/faq"
                                      className="flex-fill text-lg-center text-white nav-link align-items-center">
                                    <span className="d-lg-inline">S.S.S. </span><FaQuestion/>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/contact"
                                      className="flex-fill text-lg-center text-white nav-link align-items-center">
                                    <span className="d-lg-inline">İletişim </span><FaPhone/>
                                </Link>
                            </li>
                        </ul>

                    </div>

                    {showCategories && (
                            <ul className="navbar-nav mb-2 mt-1 w-100 p-2 rounded"
                                style={{backgroundColor: "#ffffe6"}}>
                                {categories && categories.length > 0 && categories.map((category, index) => (
                                    <li key={index}
                                        className="flex-lg-fill text-lg-center nav-link align-items-center">
                                        <Link
                                            to={`/category/${category.category_name}`}
                                            className="flex-lg-fill text-lg-center nav-link align-items-center">
                                            {category.category_name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>)}

                </div>
            </div>
        </nav>
    );
};

export default Navbar;
