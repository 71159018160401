import "./slideUp.css";
import React, {useEffect, useRef, useState} from "react";

const SlideUpDiv = ({children}) => {
    const [show, setShow] = useState(false);
    const divRefs = useRef([]); // Use an array to store refs for each child

    // Clone the children and add class and individual refs
    const enhancedChildren = React.Children.map(children, (child, index) => {
        // Only clone if child is a valid React element
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                className: `slide-up ${show ? "show" : ""} ${child.props.className || ""}`, // Add conditional class
                ref: (el) => (divRefs.current[index] = el), // Assign a separate ref for each child
            });
        }
        return child; // If it's not a valid React element, return it as is
    });

    // Lazy loading for animation
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setShow(true);
                    observer.unobserve(entry.target);
                }
            });
        });

        divRefs.current.forEach((divElement) => {
            if (divElement) observer.observe(divElement);
        });

        return () => {
            divRefs.current.forEach((divElement) => {
                if (divElement) observer.unobserve(divElement);
            });
        };
    }, [children]);

    return <>{enhancedChildren}</>;
};

export default SlideUpDiv;
