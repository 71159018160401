import {FaEnvelope, FaEye, FaInfoCircle, FaInstagramSquare, FaLock, FaPhoneSquare} from "react-icons/fa";
import {Link} from "react-router-dom";
import React from "react";
import '../App.css';


const Footer = () => {
    return (
        <footer className="row p-3 bottom-0" style={{"backgroundColor": "rgba(255, 255, 204, 0.8)"}}>
            <div
                className="col-12 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">

                <div className="col-10 col-lg-3 mt-2 mb-2 d-flex justify-content-center">
                    <img src="https://www.suencu.com/Logo.png" alt="Logo"
                         style={{"width": "200px", "height": "auto"}}/>
                </div>

                <ul className="list-group list-group-flush rounded mt-2 mb-lg-2 col-10 col-lg-3">
                    <li className="list-group-item" style={{"backgroundColor": "rgba(255, 255, 204, 0.8)"}}>
                        <div className="d-flex justify-content-between align-items-center">
                            <FaInfoCircle/>
                            <Link className="text-decoration-none footer-link"
                                  to="/hakkimizda">Hakkımızda</Link>
                        </div>
                    </li>
                    <li className="list-group-item" style={{"backgroundColor": "rgba(255, 255, 204, 0.8)"}}>
                        <div className="d-flex justify-content-between align-items-center">
                            <FaEye/>
                            <Link className="text-decoration-none footer-link" to="/vizyon">Vizyon ve
                                Misyon</Link>
                        </div>
                    </li>
                    <li className="list-group-item" style={{"backgroundColor": "rgba(255, 255, 204, 0.8)"}}>
                        <div className="d-flex justify-content-between align-items-center">
                            <FaLock/>
                            <Link className="text-decoration-none footer-link" to="/kvkk">KVKK</Link>
                        </div>
                    </li>
                </ul>

                <ul className="list-group list-group-flush rounded mt-lg-2 mb-lg-2 col-10 col-lg-3">
                    <li className="list-group-item" style={{"backgroundColor": "rgba(255, 255, 204, 0.8)"}}>
                        <div className="d-flex justify-content-start align-items-center">
                            Bizimle İletişime Geçin!
                        </div>
                    </li>
                    <li className="list-group-item" style={{"backgroundColor": "rgba(255, 255, 204, 0.8)"}}>
                        <div className="d-flex justify-content-between align-items-center">
                            <FaPhoneSquare/>
                            <a className="text-decoration-none footer-link" href="tel:+905365522420"
                               target="_blank">+90 (536) 552 24 20</a>
                        </div>
                    </li>
                    <li className="list-group-item" style={{"backgroundColor": "rgba(255, 255, 204, 0.8)"}}>
                        <div className="d-flex justify-content-between align-items-center">
                            <FaEnvelope/>
                            <a className="text-decoration-none footer-link" href="mailto:info@suencu.com"
                               target="_blank">info@suencu.com</a>
                        </div>
                    </li>
                    <li className="list-group-item"
                        style={{"backgroundColor": "rgba(255, 255, 204, 0.8)"}}>  {/*  style={{"backgroundColor": "#ffffe6"}} */}
                        <div className="d-flex justify-content-between align-items-center">
                            <FaInstagramSquare/>
                            <a className="text-decoration-none footer-link"
                               href="https://www.instagram.com/suencutr"
                               target="_blank">@suencutr</a>
                        </div>
                    </li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;