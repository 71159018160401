import React, { useState, useEffect, useRef } from "react";
import logo from "../../logo.jpg";
import { CardImg, Image } from "react-bootstrap";
import { FaShoppingCart } from "react-icons/fa";
import './item.css';
import '../slideUp.css';
import { highlightText } from "../../utils";

const Item = ({ item, searchQuery, setItemLoaded, itemLoaded }) => {
    const [onHover, setOnHover] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [showDiv, setShowDiv] = useState(false); // Initialize with `false`
    const imgRef = useRef(null); // Ref for lazy loading

    const imagePath = item.image_path ? `/items/${item.image_path}` : logo;

    const toggleHover = () => {
        setOnHover(!onHover);

        if (!showDiv && !onHover) {
            setShowDiv(true);
        }
    };

    // Detect mobile devices
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setIsMobile(true);
        }
    }, []);

    // Lazy loading for images using Intersection Observer
    useEffect(() => {
        const imgElement = imgRef.current;

        if (itemLoaded) {
            setShowDiv(true); // Ensure visibility if already loaded
            return;
        }

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Load the image and show the div
                    imgElement.src = imgElement.dataset.src;
                    setShowDiv(true);
                    setItemLoaded(item.item_id);
                    observer.unobserve(imgElement); // Stop observing once loaded
                }
            });
        });

        if (imgElement) {
            observer.observe(imgElement);
        }

        return () => {
            if (imgElement) observer.unobserve(imgElement);
        };
    }, [imagePath, item, itemLoaded, setItemLoaded, imgRef]);

    // Ensure the div is shown when hovered over
    useEffect(() => {
        if (onHover) {
            setShowDiv(true);
        }
    }, [onHover]);

    return (
        <div className="col-lg-4 col-sm-8 item d-md-flex">
            {!onHover && (
                <div className={"card p-3 m-3 border border-1 rounded align-items-center slide-up " + (showDiv ? "show" : "")}
                     onClick={isMobile ? toggleHover : null}>
                    <CardImg
                        ref={imgRef} // Lazy loading ref
                        src={!itemLoaded ? logo : "https://www.suencu.com/" + imagePath} // Placeholder
                        data-src={"https://www.suencu.com/" + imagePath} // Lazy-load image path
                        className="card-img-top p-3 border border-1 rounded item-img"
                        onMouseEnter={!isMobile ? () => setOnHover(true) : null}
                        onMouseLeave={!isMobile ? () => setOnHover(false) : null}
                        onClick={isMobile ? toggleHover : null}
                    />
                    <div className="card-body">
                        <h5 className="card-title">
                            {highlightText(item.item_name, searchQuery)}
                        </h5>
                        <div className="card-text item-card-text flex-md-grow-1">
                            <p className="item-p">{highlightText(item.description, searchQuery)}</p>
                            <div className="d-flex justify-content-end align-items-center m-2">
                                <a className="btn text-white" href={item.trendyol_path || "#"}
                                   target="_blank" rel="noopener noreferrer" style={{ backgroundColor: "#006400" }}>
                                    <span className="d-none d-lg-inline">Satın Al </span>
                                    <FaShoppingCart />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {onHover && (
                <div className="bg-white border border-1 rounded shadow-sm">
                    <div className="row bg-light align-items-center">
                        <Image
                            src={"https://www.suencu.com/" + imagePath} // Hover image src
                            className="card-img p-1 border border-1 rounded shadow-sm item-hover-img"
                            onClick={isMobile ? toggleHover : null}
                            onMouseEnter={!isMobile ? () => setOnHover(true) : null}
                            onMouseLeave={!isMobile ? () => setOnHover(false) : null}
                        />
                    </div>
                    <div className="row overflow-y-auto bg-white p-1" style={{ maxHeight: "300vh" }}>
                        <div className="card-body">
                            <h5 className="card-title p-2">{item.item_name}</h5>
                            <div className="card-text flex-md-grow-1">
                                <p className="item-hover-p p-2">{item.description}</p>
                                <div className="row m-2">
                                    <a className="btn text-white w-100 mx-1"
                                       href={item.trendyol_path || "#"}
                                       target="_blank" rel="noopener noreferrer"
                                       style={{ backgroundColor: "#006400" }}>
                                        <span>Satın Al </span>
                                        <FaShoppingCart />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Item;
