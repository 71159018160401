import CarouselMain from "./Carousel/CarouselMain";
import {FaBullseye, FaEye, FaGlobe, FaInfoCircle, FaLeaf, FaTree} from "react-icons/fa";
import {Helmet} from "react-helmet";
import React from "react";
import SlideUpDiv from "./SlideUpDiv";


const MainPage = () => {
    return (
        <div className="flex-column">
            <Helmet>
                <title>{`Suencu`}</title>
                <meta name="description" content="Ana sayfa"/>

                <meta property="og:title" content="Suencu"/>
                <meta property="og:description" content="Ana sayfa"/>
                <meta property="og:url" content={`https://www.suencu.com/`}/>
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="Suencu"/>

                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content="Suencu"/>
                <meta name="twitter:description" content="Ana sayfa"/>
                <meta name="twitter:site" content="@suencu"/>
            </Helmet>

            <div className="row mt-2 mb-1">
                <CarouselMain/>
            </div>
            <div className="row p-3 mt-3">
                <SlideUpDiv>
                    <div className="col-md-4 mb-3">
                        <div className="card border border-1 shadow-sm p-0" style={{"minHeight": "25vh"}}>
                            <div className="card-header" style={{"backgroundColor": "#ffcc00"}}>
                                <h5 className="text-center"><FaLeaf/><span
                                    className="mx-3 h-100"><b>Su</b>stainable</span>
                                </h5>
                            </div>
                            <div className="card-body text-center text-wrap">
                                <p>
                                    Gelecek nesillerin ihtiyaçlarını karşılayabileceği bir ortak gelecek hayal ediyoruz!
                                </p>
                            </div>
                        </div>
                    </div>
                </SlideUpDiv>

                <SlideUpDiv>
                    <div className="col-md-4 mb-3">
                        <div className="card border border-1 shadow-sm p-0" style={{"minHeight": "25vh"}}>
                            <div className="card-header" style={{"backgroundColor": "#ffcc00"}}>
                                <h5 className="text-center"><FaTree/><span
                                    className="mx-3 h-100"><b>En</b>vironmental</span>
                                </h5>
                            </div>
                            <div className="card-body text-center text-wrap">
                                <p>
                                    Yeşili, maviyi doğanın tüm renklerini çok seviyoruz!
                                </p>
                            </div>
                        </div>
                    </div>
                </SlideUpDiv>

                <SlideUpDiv>
                    <div className="col-md-4 mb-3">
                        <div className="card border border-1 shadow-sm p-0" style={{"minHeight": "25vh"}}>
                            <div className="card-header" style={{"backgroundColor": "#ffcc00"}}>
                                <h5 className="text-center"><FaGlobe/><span
                                    className="mx-3 h-100"><b>Cu</b>ltural</span>
                                </h5>
                            </div>
                            <div className="card-body text-center text-wrap">
                                <p>
                                    Ortak mirasımızda yer alanları ortak geleceğimize taşımak istiyoruz!
                                </p>
                            </div>
                        </div>
                    </div>
                </SlideUpDiv>
            </div>

            <div className="row p-3 mt-3">
                <SlideUpDiv>
                    <div className="card border border-1 shadow-sm p-0" style={{"minHeight": "25vh"}}>
                        <div className="card-header" style={{"backgroundColor": "#ffcc00"}}>
                            <h5 className="text-center"><FaInfoCircle/><span className="mx-3 h-100">Hakkımızda</span>
                            </h5>
                        </div>
                        <div className="card-body text-start text-wrap">
                            <p>
                                SUENCU markası “Sustainable (Sürdürülebilir)” “Environmental (Ekolojik)” ve
                                “Cultural (Kültürel)” kavramları üzerine temellendirilmiştir. Suencu, ürünlerini bu
                                temeller
                                üzerine üretmeye veya tedarik etmeye önem verir. Ekolojik hayatın devamlılığını
                                savunmak,
                                sürdürülebilirliği teşvik etmek, kültürel temeli olan ürün ve sanatların devamlılığı
                                için
                                çaba sarf
                                etmek, hayvan dostu konsepte yer vermek temel prensiplerdendir. Suencu aynı zamanda
                                yerel
                                üreticilerle iş birliğine ve yerel kalkınmaya önem verir.
                            </p>
                        </div>
                    </div>
                </SlideUpDiv>
            </div>
            <div className="row p-3 mt-3">
                <SlideUpDiv>
                    <div className="col-md-6 mb-3">
                        <div className="card border border-1 shadow-sm p-0" style={{"minHeight": "25vh"}}>
                            <div className="card-header" style={{"backgroundColor": "#ffcc00"}}>
                                <h5 className="text-center"><FaEye/><span className="mx-3 h-100">Vizyon</span></h5>
                            </div>
                            <div className="card-body text-start text-wrap">
                                <p>
                                    Sürdürülebilir, çevreye duyarlı veya kültürel temalı ürünlerle farklılık yaratarak
                                    SKA
                                    (SÜRDÜRÜLEBİLİR KALKINMA AMAÇLARI) doğrultusunda sektörde öncü rol oynamaktır.
                                </p>
                            </div>
                        </div>
                    </div>
                </SlideUpDiv>
                <SlideUpDiv>
                    <div className="col-md-6 mb-3">
                        <div className="card border border-1 shadow-sm p-0" style={{"minHeight": "25vh"}}>
                            <div className="card-header" style={{"backgroundColor": "#ffcc00"}}>
                                <h5 className="text-center"><FaBullseye/><span className="mx-3 h-100">Misyon</span></h5>
                            </div>
                            <div className="card-body text-start text-wrap">
                                <p>
                                    Dinamik ve yenilikçi bakış açısıyla standardı yüksek, kalıcı değer yaratan
                                    uygulamalarla
                                    tüketicilere doğru hizmet ve ürünleri sunarak topluma fayda sağlamaktır.
                                </p>
                            </div>
                        </div>
                    </div>
                </SlideUpDiv>
            </div>
        </div>
    );
};

export default MainPage;
